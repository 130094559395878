import { Nav } from "./components/autodesignnav"
import "./automation.css";
import { AutoDesignContent } from "./components/automationdesigncontent";

export const AutomationDesign =()=>{
    return(
        <>
            <Nav/>
            <AutoDesignContent/>
        </>
    )
}