export  const userdata =[
    {
        id:1,
        name:"Recipients",
        number:30
    },
    {
        id:2,
        name:"Delivered",
        number:50
    },
    {
        id:3,
        name:"Failed",
        number:130
    },
    {
        id:4,
        name:"Open",
        number:200
    },
    {
        id:5,
        name:"Click",
        number:310
    },
    {
        id:6,
        name:"Report",
        number:320
    },
    {
        id:7,
        name:"Unsubscribed",
        number:390
    }
]

export  const subcriberdata =[
    {
        id:1,
        name:"11/2022",
        number:30
    },
    {
        id:2,
        name:"12/2022",
        number:50
    },
    {
        id:3,
        name:"13/2022",
        number:130
    },
    {
        id:4,
        name:"14/2022",
        number:200
    },
    {
        id:5,
        name:"15/2022",
        number:310
    },
    {
        id:6,
        name:"16/2022",
        number:320
    },
    {
        id:7,
        name:"17/2022",
        number:390
    }
]